// Function to get URL parameters
function getUrlParams() {
  return new URLSearchParams(window.location.search);
}

// Function to save UTM parameters to localStorage
function saveUtmToLocalStorage() {
  const params = getUrlParams();
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

  utmParams.forEach(param => {
    if (params.has(param)) {
      localStorage.setItem(param, params.get(param));
    }
  });
}

// Function to inject UTM data into input field
function injectUtmData() {
  const utmInput = document.getElementById('user_utm');

  if (utmInput) {
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    const utmData = {};

    utmParams.forEach(param => {
      const value = localStorage.getItem(param);
      if (value) {
        utmData[param] = value;
      }
    });

    if (Object.keys(utmData).length > 0) {
      utmInput.value = JSON.stringify(utmData);
    }
  }
}

// Function to handle page load
function handlePageLoad() {
  saveUtmToLocalStorage();
  injectUtmData();
}

// Call the function when the page loads
window.addEventListener('load', handlePageLoad);
